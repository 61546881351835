import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

const useGetUser = () => {
  const store = useStore();
  const router = useRouter()
  const getInfoUser = async () => {
    const { status } = await store.dispatch('auth/checkAuthentication');

    if (status) {
      const { success, user } = await store.dispatch('user/getInfoUser');

      if (success) {
        store.commit('user/setuserData', { userData: user });
        localStorage.setItem("x-user", JSON.stringify(user))
      }else{
        store.commit("auth/logout");
        router.push('/');
      }
    }
  };
  
  return {
    getInfoUser,
  };
};

export default useGetUser;
